<template>
  <div
    :class="compact ? 'link-list--compact' : 'link-list'"
  >
    <span class="link-list__label">
      {{ label }}
    </span>
    <ul
      class="link-list__list"
    >
      <li
        v-for="linkItem in links"
        :key="linkItem.href"
      >
        <a
          :href="linkItem.href"
          class="link-list__item"
        >
          <div
            class="link-list__item-title-wrapper"
          >
            <span
              class="link-list__item-title"
            >
              {{ linkItem.title }}
            </span>
          </div>
          <span
            class="link-list__item-icon-wrapper"
          >
            <svg
              focusable="false"
              viewBox="0 0 24 24"
              class="svg-icon link-list__item-icon"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m20.0008 12.0001-8-8.001-1.4143 1.414L16.1727 11H4v2h12.1723l-5.5868
                5.5866 1.4141 1.4142 8.0012-8.0007z"
              />
            </svg>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    links: {
      type: Array,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
