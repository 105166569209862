<template>
  <div class="users grid grid-view-layout">
    <page-header @productsLoaded="headerLoaded = true" />
    <info-page
      class="home-view"
    >
      <div v-if="products?.length">
        <div
          v-if="!product"
          class="home-view__main"
        >
          <h1>
            Åtkomst
          </h1>
          <p>
            Please select a product to continue.
          </p>
        </div>
        <div
          v-else
          class="home-view__main"
        >
          <h1>
            Åtkomst
            <span :class="product?.name.length > 5 && 'home-view__main-product'">
              for
              {{ product?.name === "All products" ? "all products" : product?.name }}
            </span>
          </h1>
          <p>
            Where is DFP Web? Don't worry, you're still using the same tool,
            but we are in the process of consolidating DFP and AuthService into a new service called Åtkomst.
            Any products previously in <Hyperlink
              url="https://confluence.build.ingka.ikea.com/x/I5dAAg"
              text="DFP"
            /> can still be managed within <Hyperlink
              url="https://confluence.build.ingka.ikea.com/x/VLR5HQ"
              text="Åtkomst"
            />.
          </p>
          <LinkList
            class="home-view__link-list"
            label="Is something not working as expected? Do you have a suggestion for improvement? Let us know!"
            :links="[
              { title: 'Leave feedback', href: 'https://jira.digital.ingka.com/servicedesk/customer/portal/343'},
              { title: 'Get support', href: 'https://nowit.ingka.com/'},
            ]"
            compact
          />
        </div>
        <p class="home-view__version">
          <br>Version 2.1.0
        </p>
        <span
          v-for="text in changesText"
          :key="text"
          class="home-view__version"
        >
          {{ text }}<br>
        </span>
      </div>
      <div
        v-else-if="headerLoaded"
        class="home-view__access-denied"
      >
        <error-page
          title="Access denied"
          text="You don’t have permission to access Åtkomst. Please contact support for help."
          number="403"
        />
      </div>
    </info-page>
  </div>
</template>

<script>
import PageHeader from '@/components/domain/page-header.vue';
import ErrorPage from '@/components/shared/error-page.vue';
import InfoPage from '@/components/shared/info-page.vue';
import LinkList from '@/components/shared/link-list.vue';

import Hyperlink from '@ingka/hyperlink-vue';

import { useActorStore } from '@/store/actor';
import { useUserEvaluationStore } from '@/store/user-evaluation';

import { storeToRefs } from 'pinia';

import { ref } from 'vue';

export default {
  components: {
    PageHeader,
    ErrorPage,
    InfoPage,
    Hyperlink,
    LinkList,
  },

  setup() {
    const { product } = storeToRefs(useActorStore());

    const { products } = storeToRefs(useUserEvaluationStore());

    const headerLoaded = ref(false);

    const changesText = [
      '- 2.1.0 Adds possibility to delegate roles & privileges without requiring view rights',
      '- 2.0.0 This app is now standalone, meaning you don\'t have to go through a portal to reach it.',
      '- - The URL has changed from ...com/dfp-web/... to simply ...com/...',
      '- - Combined ILO FF apps & DFP Products into the dropdown. ILO FF apps will redirect to ILO FF page.',
      '- - Several fixes and bug solutions.',
    ];

    return {
      products,
      product,
      headerLoaded,
      changesText,
    };
  },
};
</script>

<style scoped>
.home-view {
  max-height: 50rem;
  overflow-y: auto;
}

.home-view__main {
  margin-top: 2.25rem;
}

.home-view__main p {
  max-width: 60ch;
  margin-top: 0.5rem;
}

.home-view__main-product::before {
  white-space: pre;
  content: '\a';
}

.home-view__version {
  padding-top: 2rem;
  font-size: var(--font-size-25);
}

.home-view__link-list {
  padding-top: 1rem;
}

@media (min-width: 46.375rem) {
  .home-view {
    max-height: calc(100vh - 15rem);
  }
}

@media (min-width: 64rem) {
  .home-view {
    max-height: calc(100vh - 12rem);
  }
}
</style>
