<template>
  <div
    class="error-page"
  >
    <div
      class="error-page__number"
    >
      {{ number }}
    </div>
    <div
      class="error-page__title"
    >
      {{ title }}
    </div>
    <div
      class="error-page__text"
    >
      {{ text }}
    </div>
    <button
      v-if="button"
      aria-disabled="false"
      type="button"
      class="error-page__button btn btn--primary btn--small"
      @click="onButtonClicked"
    >
      <span
        class="btn__inner"
      >
        <span
          class="btn__label"
        >
          {{ button }}
        </span>
      </span>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    number: {
      type: String,
      default: '',
    },
    button: {
      type: String,
      default: '',
    },
  },

  emits: ['buttonClicked'],

  setup(props, { emit }) {
    const onButtonClicked = () => {
      emit('buttonClicked');
    };
    return {
      onButtonClicked,
    };
  },
};
</script>
<style scoped>
  .error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15.25rem;
    color: var(--colour-text-dark);
  }

  .error-page__title {
    font-size: var(--font-size-700);
    font-weight: bold;
  }

  .error-page__number {
    font-size: var(--font-size-100);
    font-weight: bold;
  }

  .error-page__text {
    max-width: 22.25rem;
    margin: 0.75rem 0 2rem 0;
    color: var(--colour-text-default);
    text-align: center;
  }

  .error-page__button {
    width: 10.25rem;
  }
</style>
